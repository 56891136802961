import React from "react"
import IntegrationTemplate from "../../templates/integration"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import IntegrationStorageContent from 'components/content/integration-storage'
import { meta } from '../../configs/storage-providers';
const ContentPage = () => (
  <IntegrationTemplate
    key="azure"
    name="Microsoft Azure"
    shortDescription="Own your backups and store them off-site on Azure Blob Storage"
    website={"https://azure.microsoft.com/en-us/products/storage/blobs/"}
    links={[{text: 'Documentation', url: '/docs'}]}
    category="Storage"
    logo="azure-ico.png"
    metaTitle={meta.title.replace('##provider##', 'Azure Blob Storage')}
    metaDescription={meta.description.replace('##provider##', 'Azure Blob Storage')}
  >
    <IntegrationStorageContent
      keyRef="azure-blob"
      service="Azure Blob Storage"
      provider="Azure"
      learnTags={['azure']}
    />
  </IntegrationTemplate>
)

export default ContentPage
